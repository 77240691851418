import { useLocation } from '@reach/router'
import React, { useEffect, useState } from 'react'
import { useStripe } from '@stripe/react-stripe-js'
import tw from 'twin.macro'

import { CardContent, ErrorMessage, ErrorMessageText } from '../../components/elements'
import { StripeContainer } from '../../components/elements/Stripe'
import { ROUTES } from '../../components/layout/Navigation'
import { OnboardingStep, useMatchOnboardingState } from '../../content/onboarding/OnboardingStep'
// import profile from './images/profile-1.jpg'

import { Spinner } from '../../components/elements/Loading'
import { useEmitInitiatedCheckout, useEmitViewedPricing } from '../../components/analytics/Events'

const PricingBubble = tw.div`flex-shrink-0 w-full relative overflow-hidden rounded-lg shadow-lg`

const OnboardingStep4 = () => (
  <OnboardingStep
    step={4}
    desktopTitle={"4. Let's start your case"}
    imageUrl='https://images.unsplash.com/photo-1461897104016-0b3b00cc81ee?ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80'
  >
    <StripeContainer>
      <Quote />
      <Notices />
      {/* <ScheduleConsultation /> */}
    </StripeContainer>
  </OnboardingStep>
)

const Quote = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const cancelled = params.get('cancelled')

  const stripe = useStripe()
  const { state } = useMatchOnboardingState()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const emitViewedPricing = useEmitViewedPricing()
  const emitInitiatedCheckout = useEmitInitiatedCheckout()

  useEffect(() => emitViewedPricing(), [])

  const displayOfTypeOfCase =
    state.caseType === "eviction-notice"
      ? 'Eviction Process'
      : state.caseType === "letter"
        ? 'Letter Before Action'
        : state.caseType === "family-law"
          ? 'case'
          : state.caseType === "generic"
            ? 'case'
            : 'case'

  const quoteAmount = 57
  const displayQuotePrice = '£57'

  const checkout = async (consultation: boolean) => {
    if(!stripe) {
      return
    }
    const payload = {
      returnUrl: ROUTES.onboarding.claimSession,
      cancelUrl: ROUTES.onboarding.step4,
      submissionId: state.submissionId,
      secret: state.secret,
      isConsultation: consultation
    }

    setError(false)
    setLoading(true)

    emitInitiatedCheckout(quoteAmount, state.caseType)

    const response = await fetch('/.netlify/functions/match-onboarding-create-checkout-session', {
      method: 'POST',
      body: JSON.stringify(payload)
    })

    setLoading(false)

    if (!response.ok) {
      setError(true)
    } else {
      const data = await response.json()

      const { error } = await stripe.redirectToCheckout({
        sessionId: data.sessionId
      })

      if(error) {
        console.log('Error', error)
      }
    }
  }

  // const displayQuotePrice =
  //   state.caseType === "eviction-notice"
  //     ? '£79'
  //     : state.caseType === "letter"
  //       ? '£149'
  //       : state.caseType === "family-law"
  //         ? '£149'
  //         : state.caseType === "generic"
  //           ? '£149'
  //           : '£149'

  // const showQuote =
  //   state.caseType === "eviction-notice"
  //     ? true
  //     : state.caseType === "letter"
  //       ? true
  //       : state.caseType === "family-law"
  //         ? false
  //         : state.caseType === "generic"
  //           ? true
  //           : true

  return (
    <CardContent>

      {cancelled && (
        <ErrorMessage title="Your checkout was cancelled" tw="mb-3 sm:mb-6">
          <p tw="mt-3 font-medium text-red-500">
            You haven't been charged.
          </p>
        </ErrorMessage>
      )}

      <div tw="flex flex-col">
        {/* <div tw="flex flex-row items-center justify-start my-2">
          <div tw="relative shadow h-12 w-12 border-white rounded-full overflow-hidden border-4 flex-shrink-0">
            <img tw="object-cover w-full h-full" src={profile} />
          </div>
          <div tw="ml-4">
            <h1 tw="text-lg font-semibold">
              Jas U.
            </h1>
            <p tw="text-sm text-gray-600">
              SRA Licensed Solicitor
            </p>
          </div>
        </div> */}
        {/* <h1 tw="text-lg font-bold text-gray-700">
          Checkout
        </h1> */}
      </div>

      {/* <div tw="flex flex-row items-center justify-start my-2">
        <div tw="relative shadow h-12 w-12 border-white rounded-full overflow-hidden border-4 flex-shrink-0">
          <img tw="object-cover w-full h-full" src={profile} />
        </div>
        <div tw="ml-4">
          <h1 tw="text-lg font-semibold">
            Jas U.
          </h1>
          <p tw="text-sm text-gray-600">
            SRA Licensed Solicitor
          </p>
        </div>
      </div>

      <p tw="mb-6 mt-4 rounded-md rounded-bl-none px-4 py-3 bg-blue-100 text-sm text-blue-700 ">
        Jas would like to ask you more about your case
        to determine what the best course of action is.
      </p> */}
      <div tw="flex flex-wrap items-center justify-center mt-2">
        <PricingBubble tw="bg-indigo-500">
          <div tw="relative text-white px-6 pb-6 mt-6">
            <span tw="block opacity-75 -mb-1">Flight Cancellation</span>
            <div tw="flex justify-between items-center">
              <span tw="block font-semibold text-xl">
                Start your {displayOfTypeOfCase} now
              </span>
            </div>
            <div tw="flex justify-between items-center mt-2">
              <span tw="block bg-indigo-600 text-white rounded-full text-xs font-bold px-3 py-2 leading-none flex items-center">
                {displayQuotePrice}
              </span>
              <button
                type="button"
                tw="block text-indigo-600 bg-white rounded-full text-xs font-bold px-3 py-2 leading-none flex items-center"
                onClick={() => checkout(false)}
                disabled={loading}
              >
              {loading ? <Spinner tw="h-3 w-20" /> : <>Checkout now</>}
              </button>
            </div>
          </div>
        </PricingBubble>

            {/* <div tw="py-3 text-gray-500 text-sm font-semibold tracking-wider uppercase">
              or
            </div> */}

        {/* <PricingBubble tw="bg-cool-gray-500">
          <div tw="relative text-white px-6 pb-6 mt-6">
            <span tw="block opacity-75 -mb-1">Talk to your Solicitor</span>
            <div tw="flex justify-between items-center">
              <span tw="block font-semibold text-xl">Request 30 minute consultation</span>
            </div>

            <div tw="flex justify-between items-center mt-2">
              <span tw="block bg-cool-gray-600 text-white rounded-full text-xs font-bold px-3 py-2 leading-none flex items-center">
                £39
              </span>
              <button
                type="button"
                tw="block text-cool-gray-600 bg-white rounded-full text-xs font-bold px-3 py-2 leading-none flex items-center"
                onClick={() => checkout(true)}
                disabled={loading}
              >
                {loading ? <Spinner tw="h-3 w-20" /> : <>Checkout now</>}
              </button>
            </div>
          </div>
        </PricingBubble> */}
      </div>

      {error && (
        <ErrorMessage tw="mt-4" title={'Something went wrong'}>
          <ErrorMessageText>
            Please try again or contact <a href="mailto:support@lawletter.co">support@lawletter.co</a>
          </ErrorMessageText>
        </ErrorMessage>
      )}
    </CardContent>
  )
}

const Notices = () => (
  <CardContent tw="bg-gray-100 text-gray-600">
    <p tw="text-sm">
    After payment confirmation, <span tw="font-medium text-indigo-600">Jas</span> will get started on your case immediately.
    </p>

  <p tw="mt-4 text-sm text-gray-800 font-semibold">
    If you change your mind within 48 hours, you can receive a full refund.
  </p>
</CardContent>
)

// const ScheduleConsultation = () => {
//   const [loading, setLoading] = useState(false)
//   const [error, setError] = useState(false)

//   const { state } = useMatchOnboardingState()
//   const stripe = useStripe()

//   const checkout = async () => {
//     if(!stripe) {
//       return
//     }
//     const payload = {
//       returnUrl: ROUTES.onboarding.claimSession,
//       cancelUrl: ROUTES.onboarding.step4,
//       submissionId: state.submissionId,
//       secret: state.secret,
//       isConsultation: true
//     }

//     setError(false)
//     setLoading(true)

//     const response = await fetch('/.netlify/functions/match-onboarding-create-checkout-session', {
//       method: 'POST',
//       body: JSON.stringify(payload)
//     })

//     setLoading(false)

//     if (!response.ok) {
//       setError(true)
//     } else {
//       const data = await response.json()

//       const { error } = await stripe.redirectToCheckout({
//         sessionId: data.sessionId
//       })

//       if(error) {
//         console.log('Error', error)
//       }
//     }
//   }

//   return (
//     <CardContent tw="bg-gray-100 text-gray-600">

//       <p tw="font-medium sm:text-lg text-gray-700">
//         Not sure on the right course of action?
//       </p>

//       <p tw="text-sm mt-2 sm:text-base">
//         Have a 30 minute call
//         with <span tw="font-medium text-indigo-600">Jas</span> about your case.
//       </p>

//       <p tw="text-gray-900 text-base sm:text-lg mt-2">
//         <span tw="font-semibold">£39</span> for a 30 minute conversation
//       </p>

//       <div tw="mt-4 sm:mt-8 mb-2">

//         <FormButton
//             tw="text-indigo-600 bg-indigo-200 hover:bg-indigo-100"
//             disabled={loading}
//             onClick={checkout}
//           >
//             {loading ? <Spinner tw="h-6" /> : <>Speak to Jas now</>}
//           </FormButton>

//       </div>

//       {error && (
//         <ErrorMessage tw="mb-4" title={'Something went wrong'}>
//           <ErrorMessageText>
//             Please try again or contact <a href="mailto:support@lawletter.co">support@lawletter.co</a>
//           </ErrorMessageText>
//         </ErrorMessage>
//       )}
//     </CardContent>
//   )
// }

export default OnboardingStep4
